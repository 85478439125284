@import "@/assets/scss/style.scss";

.dashboards-wrapper {
    margin: 2rem auto;
    width: 80vw;

    .dasboards__top-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 3rem;

        & > div {
            cursor: pointer;
            font-size: 1rem;
        }

        & > div:first-child {
            margin-left: auto;
            padding-right: 0.5rem;
            border-right: 1px solid rgb(212, 212, 212);
        }

        & > div:nth-child(2) {
            padding-left: 0.5rem;
        }

        .dashboards__title {
            font-size: 1rem;
            color: rgb(39, 39, 39);
        }

        button {
            font-size: 1rem;
            margin-left: auto;
        }
    }
    .dashboards-content {
        padding: 2rem;
        border: 1px solid rgb(212, 212, 212);

        .dashboards-table-wrapper {
            overflow-x: auto;

            .ant-table-wrapper {
                min-width: max-content;
            }
        }
    }

    .delete-icon {
        font-size: 20px;
        color: red;
    }

    .dashboards__table-row {
        cursor: pointer;
    }
}
